@import "../../styles/variable.module.scss";
.footer {
  border-top: 1px solid var(--border-color);
  padding-top: 64px;
  @include large_device {
    padding-left: 3px;
    padding-right: 3px;
  }
  @include medium_device {
    padding-top: 32px;
  }
  .footer__center {
    padding: 48px 0;
    @include medium_device{
      padding: 32px 0;
    }
    .footerWidget {
      .footer__logo {
        margin-bottom: 32px;
        @include medium_device{
          margin-bottom: 25px;
        }
        .dflex{
          span{
            > span{
              display: flex !important;
            }
          }
        }
        span {
          img {
            object-fit: contain;
          }
        }
      }
      .selectMenu {
        & > div {
          & > [class$="control"] {
            border-radius: $borderRadius;
          }
        }
      }
      &:first-child {
        width: 36%;
        @include large_device {
          width: 50%;
        }
        @include medium_device {
          width: 100%;
          .subscription {
            max-width: 100%;
          }
        }
      }
      &:nth-child(2) {
        width: 32%;
        @include large_device {
          margin-left: 0;
          margin-right: 0;
          width: 50%;
        }
        @include medium_device {
          margin-top: 32px;
          width: 100%;
        }
        .footer__menu{
          display: block;
          ul{
            column-count: 2;
            column-gap: 6px;
          }
        }
      }
      &:nth-child(3),
      &:last-child {
        width: 16%;
        @include large_device {
          margin-top: 32px;
          width: 50%;
        }
      }
      &:last-child {
        @include large_device {
          .selectMenu {
            width: calc(100% - 90px);
            margin-left: 20px;
          }
        }
        @include medium_device {
          padding-left: 0;
        }
      }
      .footer__head {
        margin-bottom: 24px;
        font-size: 22px;
        line-height: 1.35;
        @include fontWeight(font-weight, 600, 700);
        @include mobile_device {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 26px;
        }
      }
      .footer__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: -14px;
        @include medium_device{
          margin-bottom: -8px;
        }
        .footer__link {
          font-size: 16px;
          line-height: 22px;
          color: var(--light-font);
          transition: color .2s;
          margin-bottom: 14px;
          display: inline-block;
          position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 0;
              height: 1px;
              bottom: 1px;
              background: $themeColor;
              -webkit-transition: width .4s;
              transition: width .4s;
            }
            &:hover {
              color: $themeColor;
              &::before {
                width: 100%;
              }
            }
          @include medium_device {
            margin-bottom: 8px;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    .footer__info {
      font-size: 16px;
      line-height: 24px;
      color: var(--light-font);
      margin-bottom: 32px;
      max-width: 310px;
      @include medium_device {
        font-size: 14px;
        line-height: 20px;
        max-width: 100%;
      }
    }
    .subscription {
      position: relative;
      max-width: 300px;
      @include mobile_device {
        max-width: 100%;
      }
      input {
        height: 48px;
        border-radius: 50px;
        padding-right: 50px;
        &::placeholder {
          font-size: 13px;
        }
      }
      .subscription__btn {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 8px;
        bottom: 8px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $themeColor;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        .colors *{
          fill: $lightColor;
        }
      }
    }
  }
  .footer__details {
    color: var(--desc-font);
    @include fontWeight(font-weight, 600, 700);
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
    @include medium_device {
      font-size: 18px;
      line-height: 26px;
    }
  }
  .footer__top {
    img {
      object-fit: contain;
    }
    & + .footer__center {
      & > .footerWidget {
        @include large_device {
          width: 100%;
          &:last-child {
            margin-top: 32px;
          }
        }
      }
    }
    &.border_bottom {
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 51px;
      @include medium_device {
        padding-bottom: 32px;
      }
    }
    .right {
      @include medium_device {
        width: 100%;
      }
      .footer__details {
        @include medium_device {
          margin-top: 30px;
          padding-top: 32px;
          border-top: 1px solid var(--border-color);
        }
      }
    }
    .footer__connection_list {
      @include medium_device {
        flex-wrap: wrap;
      }
      li {
        &:not(:last-child) {
          margin-right: 12px;
        }
        a {
          width: 50px;
          height: 50px;
          background: var(--light-bg);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
          &:hover {
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            -webkit-box-shadow: 0 7px 10px 0 rgb(20 20 22 / 15%);
            box-shadow: 0 7px 10px 0 rgb(20 20 22 / 15%);
          }
        }
      }
    }
    .dark {
      display: none !important;
    }
  }
  .footer__foot {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    font-size: 16px;
    line-height: 24px;
    border-top: 1px solid var(--border-color);
    @include medium_device {
      padding: 20px 0;
      flex-direction: column;
      text-align: center;
      line-height: 1.66667;
      font-size: 13px;
    }
    .footer__copyright {
      color: var(--light-font);
      @include medium_device {
        margin-bottom: 10px;
      }
    }
    .footer__note_link{
      margin: 0 -8px;
    }
    .footer__link {
      color: var(--light-font);
      font-size: 16px;
      position: relative;
      transition: color 0.2s;
      line-height: 24px;
      margin: 0 8px;
      @include medium_device{
        line-height: 1.66667;
        font-size: 13px;
      }
      &:hover {
        color: $themeColor;
      }
    }
  }
}
.didFooter{
  padding-top: 50px;
  border: none;
  @include medium_device{
    padding-top: 30px;
  }
  .footer__center{
    padding: 45px 0;
    @include medium_device{
      padding: 30px 0;
    }
    > div{
      @media only screen and (min-width: 992px){
        margin: 0 -17px;
        flex-wrap: inherit;
      }
    }
    .footerWidget{
      @media only screen and (min-width: 992px){
      padding: 0 17px;
      }
      width: auto !important;
      flex: 1 1 auto;
      @include medium_device{
        width: 50% !important;
      }
      &:first-child{
        width: 26.8% !important;
        @include medium_device{
          width: 100% !important;
        }
      }
      &:last-child{
        padding-left: 12px;
      }
      &:nth-child(2){
        .footer__menu{
          display: flex;
          ul{
            column-count: initial;
          }
        }
      }
    }
  }
}
[dir="rtl"] {
  .footer {
    .footer__top {
      .footer__connection_list {
        li {
          &:not(:last-child) {
            margin-left: 12px;
            margin-right: 0;
          }
        }
      }
    }
    .footer__center {
      .subscription {
        input {
          padding-left: 50px;
          padding-right: 16px;
        }
        .subscription__btn {
          left: 8px;
          right: auto;
          svg {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
